<template>
    <div class="box">
        <h2>
            {{ crefoFilteredList.label }}
            <small>{{ crefoFilteredList.keylist.key }}</small>
        </h2>
        <div class="body">
            <table>
                <thead>
                    <tr>
                        <th></th>
                        <th>Key</th>
                        <th>Name</th>
                        <th>Kurzform</th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="clickable-row" v-for="entry in crefoFilteredList.keylist.entries" v-bind:key="entry.key">
                        <td><input type="checkbox" v-model="entry.enabled" :id="'entry_' + entry.key" /><label :for="'entry_' + entry.key"></label></td>
                        <td>{{ entry.key }}</td>
                        <td>{{ entry.designation }}</td>
                        <td>{{ entry.shortdesignation }}</td>
                    </tr>
                </tbody>
            </table>

            <hr />

            <div class="button-row">
                <button @click="save()" type="button" class="primary size-l"><i class="fal fa-save"></i> Speichern</button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            crefoFilteredList: {
                label: "",
                keylist: {
                    key: "",
                    entries: []
                },
                allowed: []
            }
        };
    },
    created() {
        this.fetchData();
    },
    methods: {
        fetchData() {
            this.$api.get("/crefo/filteredlist/" + this.$route.params.id).then(
                response => {
                    this.crefoFilteredList = response.data.data;
                    // Use "allowed" to add enabled state to entries
                    for (var e = 0; e < this.crefoFilteredList.keylist.entries.length; e++) {
                        this.crefoFilteredList.keylist.entries[e].enabled = false;

                        if (this.crefoFilteredList.allowed === null) {
                            continue;
                        }
                        for (var a = 0; a < this.crefoFilteredList.allowed.length; a++) {
                            if (this.crefoFilteredList.keylist.entries[e].key === this.crefoFilteredList.allowed[a]) {
                                this.crefoFilteredList.keylist.entries[e].enabled = true;
                            }
                        }
                    }
                },
                () => {
                    this.$snotify.error("Fehler beim Laden der Listen");
                }
            );
        },
        save() {
            var allowed = [];
            for (var e = 0; e < this.crefoFilteredList.keylist.entries.length; e++) {
                if (this.crefoFilteredList.keylist.entries[e].enabled) {
                    allowed.push(this.crefoFilteredList.keylist.entries[e].key);
                }
            }

            this.$api.put("/crefo/filteredlist/" + this.$route.params.id, allowed).then(
                () => {
                    this.$snotify.success("List saved");
                },
                () => {
                    this.$snotify.error("Save failed");
                }
            );
        }
    }
};
</script>
